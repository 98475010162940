import { startRegistration } from "@simplewebauthn/browser";
import { Label, TextInput, Button } from "flowbite-react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { faxios, parseAxiosError } from "../utils/axios";

interface RegisterProps {
  username: string;
  setUsername: (username: string) => void;
}

export const Register: React.FC<RegisterProps> = (props) => {
  const { username, setUsername } = props;

  const [invitationKey, setInvitationKey] = useState("");

  const navigate = useNavigate();

  const onRegister = () => {
    const registerPromise = register(username, invitationKey);
    toast.promise(registerPromise, {
      loading: "Loading...",
      success: "Succesfully registered",
      error: (err) => parseAxiosError(err) ?? "Error. Please retry",
    });

    registerPromise.then(() => {
      navigate("login");
    });
  };

  return (
    <form onSubmit={onRegister}>
      <div>
        <Label htmlFor="username">Username</Label>
      </div>
      <div>
        <TextInput
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div>
        <Label htmlFor="invitation">Invitation key</Label>
      </div>
      <div>
        <TextInput
          id="invitation"
          value={invitationKey}
          onChange={(e) => setInvitationKey(e.target.value)}
        />
      </div>
      <div className="mt-6">
        <Button onClick={onRegister} className="mb-2">
          Register
        </Button>
      </div>
    </form>
  );
};

async function register(username: string, invitationKey: string) {
  const resp = await faxios.post("/auth/register/init", {
    username,
    invitation_key: invitationKey,
  });

  const attResp = await startRegistration(resp.data);

  await faxios.post(`/auth/register/verify/${resp.data.user.id}`, attResp);
}
