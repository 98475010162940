import { Button, Label, TextInput } from "flowbite-react";
import { startAuthentication } from "@simplewebauthn/browser";
import { toast } from "react-hot-toast";
import { faxios, parseAxiosError } from "../utils/axios";
import { useNavigate } from "react-router-dom";

interface LoginProps {
  username: string;
  setUsername: (username: string) => void;
}

export const Login: React.FC<LoginProps> = (props) => {
  const { username, setUsername } = props;

  const navigate = useNavigate();

  const onLogin = () => {
    const loginPromise = login(username);
    toast.promise(loginPromise, {
      loading: "Loading...",
      success: "Logged in!",
      error: (err) => parseAxiosError(err) ?? "Error. Please retry",
    });

    loginPromise.then(() => {
      navigate("/app");
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onLogin();
      }}
    >
      <div>
        <Label htmlFor="username">Username</Label>
      </div>
      <div>
        <TextInput
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          autoComplete="webauthn username"
        />
      </div>
      <div className="mt-6">
        <Button onClick={onLogin} className="mb-2">
          Login
        </Button>
      </div>
    </form>
  );
};

async function login(username: string) {
  const resp = await faxios.post(`/auth/login/init`, {
    username,
  });

  const userId = resp.data.user_id;

  const asseResp = await startAuthentication(resp.data);

  await faxios.post(`/auth/login/verify/${userId}`, asseResp);
}
