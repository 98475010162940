import { Button, Card } from "flowbite-react";
import {
  createHashRouter,
  Link,
  Navigate,
  redirect,
  RouterProvider,
} from "react-router-dom";
import { Home } from "./pages/app/home";
import { Auth } from "./pages/auth";

const router = createHashRouter([
  { path: "/", element: renderIndex() },
  { path: "/auth/*", element: <Auth /> },
  { path: "/app/*", element: <Home /> },
  { path: "*", element: renderNotFound() },
]);

export const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

function renderIndex() {
  return <Navigate to="/auth" />;
}

function renderNotFound() {
  return (
    <div className="h-screen flex items-center justify-center bg-slate-50">
      <Card>
        404 Not found!
        <Link to="/">
          <Button>Home page</Button>
        </Link>
      </Card>
    </div>
  );
}
