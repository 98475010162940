import axios from "axios";
import { ApiType } from "../api/helpers";

export function parseAxiosError(err: any): string | undefined {
  const errorMessage =
    err.response?.data?.message ?? err.response?.data?.error ?? err.message;

  return errorMessage ? JSON.stringify(errorMessage) : undefined;
}

export const faxios = axios.create({
  withCredentials: true,
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:2020"
      : "https://admin.notion.tamtanguy.fr",
});

export async function fetchApi<TModel>(apiType: ApiType<TModel>, path: string) {
  const res = await faxios.get(path);
  return apiType.deserialize(res.data);
}
