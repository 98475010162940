import { z } from "zod";

export interface ApiType<TModel> {
  deserialize: (data: any) => TModel;
}

export function makeApi<TModel>(
  runtimeType: z.ZodType<TModel>,
  parse: (src: any) => TModel
): ApiType<TModel> {
  return {
    deserialize: (data) => {
      const parsedData = parse(data);
      return runtimeType.parse(parsedData);
    },
  };
}
