import classNames from "classnames";
import { Button, Card } from "flowbite-react";
import ButtonGroup from "flowbite-react/lib/esm/components/Button/ButtonGroup";
import { useState } from "react";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Login } from "./login";
import { Register } from "./register";

enum Location {
  LOGIN,
  REGISTER,
}

export const Auth: React.FC = () => {
  const { pathname } = useLocation();
  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  const currentLocation = getCurrentLocation(pathname);

  return (
    <div className="h-screen flex items-center justify-center bg-blue-900">
      <Card className="w-96">
        <h1 className="font-bold text-lg">Folsion Admin</h1>
        <ButtonGroup className="flex w-full ">
          <Button
            color={currentLocation === Location.LOGIN ? undefined : "light"}
            className="flex-1"
            onClick={() => navigate("login")}
          >
            Login
          </Button>
          <Button
            color={currentLocation === Location.REGISTER ? undefined : "light"}
            className="flex-1"
            onClick={() => navigate("register")}
          >
            Register
          </Button>
        </ButtonGroup>
        <Routes>
          <Route
            path="login/"
            element={<Login username={username} setUsername={setUsername} />}
          ></Route>
          <Route
            path="register/"
            element={<Register username={username} setUsername={setUsername} />}
          ></Route>
          <Route path="*" element={<Navigate to="login" />}></Route>
        </Routes>
      </Card>
    </div>
  );
};

function getCurrentLocation(pathname: string) {
  switch (pathname) {
    case "/auth/login":
      return Location.LOGIN;

    case "/auth/register":
      return Location.REGISTER;

    default:
      return null;
  }
}
