import { z } from "zod";
import { makeApi } from "./helpers";

const RUserModel = z.object({
  id: z.string(),
  username: z.string(),
});

export interface UserModel extends z.infer<typeof RUserModel> {}

function parseUser(src: any): UserModel {
  return {
    id: src.user.id,
    username: src.user.username,
  };
}

export const ApiUser = makeApi(RUserModel, parseUser);
