import { z } from "zod";
import { makeApi } from "./helpers";

const RNotionPage = z.object({
  notionId: z.string(),
  domain: z.string(),
  notionDomain: z.string(),
});

const RNotionPages = z.array(RNotionPage);

export interface NotionPageModel extends z.infer<typeof RNotionPage> {}

function parseNotionPage(src: any): NotionPageModel {
  return {
    notionId: src.notion_id,
    domain: src.domain,
    notionDomain: src.notion_domain,
  };
}

function parseNotionPages(src: any): ReadonlyArray<NotionPageModel> {
  return (src.notion_pages ?? []).map(parseNotionPage);
}

export const ApiNotionPages = makeApi(RNotionPages, parseNotionPages);
