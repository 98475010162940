import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Avatar,
  Button,
  Dropdown,
  Label,
  Modal,
  Navbar,
  Table,
  TextInput,
} from "flowbite-react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ApiNotionPages, NotionPageModel } from "../../api/notionPage";
import { ApiUser } from "../../api/user";
import { faxios, fetchApi, parseAxiosError } from "../../utils/axios";

export const Home: React.FC = () => {
  const navigate = useNavigate();

  const [domainEdit, setDomainEdit] = useState<string>();
  const [newNotion, setNewNotion] = useState<{ id: string; domain: string }>();

  const setNotionId = (notionId: string) => {
    setNewNotion((prev) =>
      prev !== undefined ? { ...prev, id: notionId } : undefined
    );
  };
  const setNotionDomain = (notionDomain: string) => {
    setNewNotion((prev) =>
      prev !== undefined ? { ...prev, domain: notionDomain } : undefined
    );
  };

  const { data: user } = useQuery({
    queryKey: ["users", "me"],
    queryFn: async () => {
      return fetchApi(ApiUser, "/me");
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { data: notionPages } = useQuery({
    queryKey: ["notionPages"],
    queryFn: async () => {
      return fetchApi(ApiNotionPages, "/notion-pages");
    },
  });

  const queryClient = useQueryClient();

  const onModalClose = () => {
    setDomainEdit(undefined);
    setNewNotion(undefined);
  };

  const onEdit = (page: NotionPageModel) => {
    setDomainEdit(page.domain);
    setNewNotion({ id: page.notionId, domain: page.notionDomain });
  };

  const onSave = () => {
    if (!newNotion) {
      return;
    }

    const savePromise = faxios.post("/notion-pages", {
      domain: domainEdit,
      notion_id: newNotion.id,
      notion_domain: newNotion.domain,
    });

    toast.promise(savePromise, {
      loading: "Saving...",
      error: (err) => parseAxiosError(err) ?? "Error",
      success: "Saved!",
    });

    savePromise.then(() => {
      queryClient.invalidateQueries(["notionPages"]);
      onModalClose();
    });
  };

  const onSignout = async () => {
    await faxios.get("/auth/logout");
    navigate("/");
  };

  const renderModal = () => {
    return (
      <>
        <Modal show={domainEdit !== undefined} onClose={onModalClose}>
          <Modal.Header>Edit</Modal.Header>
          <Modal.Body>
            <div>
              <div>Domain: {domainEdit}</div>
              <div className="mt-4">
                <Label htmlFor="new-notion-id">Notion ID</Label>
                <TextInput
                  id="new-notion-id"
                  value={newNotion?.id}
                  onChange={(e) => setNotionId(e.target.value)}
                  className="mt-2"
                />
                <TextInput
                  id="new-notion-domain"
                  value={newNotion?.domain}
                  onChange={(e) => setNotionDomain(e.target.value)}
                  className="mt-2"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onSave}>Save</Button>
            <Button color="gray" onClick={onModalClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const renderNotionPages = () => {
    return (
      <Table>
        <Table.Head className="border-b border-gray-200">
          <Table.HeadCell>Domain</Table.HeadCell>
          <Table.HeadCell>Notion ID</Table.HeadCell>
          <Table.HeadCell>Notion domain</Table.HeadCell>
          <Table.HeadCell>
            <span className="sr-only">Edit</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {notionPages === undefined
            ? null
            : notionPages.map((page) => (
                <Table.Row
                  key={page.domain}
                  className="bg-white border-b border-gray-100"
                >
                  <Table.Cell>{page.domain}</Table.Cell>
                  <Table.Cell>{page.notionId}</Table.Cell>
                  <Table.Cell>{page.notionDomain}</Table.Cell>
                  <Table.Cell>
                    <Button color="gray" onClick={() => onEdit(page)}>
                      Edit
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
        </Table.Body>
      </Table>
    );
  };
  const renderHome = () => {
    if (!user) {
      return null;
    }

    return (
      <>
        {renderModal()}
        <div className="mx-4">
          <div className="mt-8 text-xl font-bold">Hello {user.username}!</div>
          <div className="mt-8">{renderNotionPages()}</div>
        </div>
      </>
    );
  };

  return (
    <div className="min-h-screen bg-green-50">
      <Navbar fluid rounded>
        <Navbar.Brand>Folsion Admin</Navbar.Brand>
        <div className="flex md:order-2">
          <Dropdown
            arrowIcon={false}
            inline={true}
            label={<Avatar alt="User settings" rounded={true} />}
          >
            <Dropdown.Header>
              <span className="block text-sm">{user?.username}</span>
            </Dropdown.Header>
            <Dropdown.Item onClick={onSignout}>Sign out</Dropdown.Item>
          </Dropdown>
          <Navbar.Toggle />
        </div>
      </Navbar>
      <div>
        <Routes>
          <Route path="/" element={renderHome()} />
        </Routes>
      </div>
    </div>
  );
};
